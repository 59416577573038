.splash-screen {
  .splash-screen-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .logo-wedding-card {
      top: 0;
      width: 480px;
      @media only screen and (max-width: 600px) {
        width: 80%;
      }
      @media only screen and (min-width: 1240px) {
        width: 680px;
      }
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 auto;
    }
  }

  .bottom-container {
    position: relative;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    animation: fadeInUp 1s forwards;
  }
  .bottom-button {
    background-color: #868686;
    color: white;
    border: none;
    cursor: pointer;
    height: 45px;
    width: 200px;
    text-align: center;
    border-radius: 40px;
    margin: 0 auto;
  }

  .bottom-button:hover {
    background-color: #555;
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate(-50%, 100px);
    }
    to {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
}
