.input {
  display: flex;
  flex-direction: column;
  gap: 4px;
  .input-label {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #3e5c76;
    span {
      color: #c28920;
    }
  }
  .input-input {
    border: none;
    border-bottom: 1px solid #2e2e2e;
    border-radius: 0px;
    font-size: 18px;
    height: 30px;
    background: none;
    color: #0c4a5c;
  }
}
