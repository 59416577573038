.columns {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  p {
    margin: 0px;
  }
}

.left {
  text-align: left;
}
.right {
  text-align: left;
}

h3 {
  font-size: 24px;
  margin: 1px;
}
.invite-card {
  text-align: center;
}

.names-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  .name-item {
    font-style: italic;
  }
}

.p-text-hebrew {
  font-size: 22px;
  @media only screen and (max-width: 760px) {
    font-size: 20px;
  }
}

.h6-text-hebrew {
  font-size: 20px;
  @media only screen and (max-width: 760px) {
    font-size: 18px;
  }
}

.no-padding {
  margin: 0px;
}
