.form-card {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #3e5c76;
  .form-card-title {
    font-size: 24px;
    text-align: center;
    color: #3e5c76;
    margin: 10px 0px 8px 0px;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
  }
  .button-container {
    display: flex;
    justify-content: center;
  }
  .react-switch-selector-wrapper {
    height: 40px;
    border-radius: 8px;
    font-weight: 800;
    background: #b7d5f0;
  }
  .react-switch-selector-option {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }
}
