@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,400;0,6..96,500;1,6..96,400;1,6..96,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");

.App {
  text-align: center;
  letter-spacing: 2px;
}

.App-container {
  padding-top: 61.5px;
  max-width: 720px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 44px;
  @media only screen and (max-width: 760px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.container-lg {
  max-width: 768px;
  margin: 0px auto;
  @media only screen and (max-width: 768px) {
    padding: 0 20px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
a {
  font-family: "Bodoni Moda", serif;
}

p {
  font-family: "Poppins", sans-serif;
  color: #3e5c76;
  font-weight: 500;
  font-size: 16px;
  @media only screen and (max-width: 760px) {
    font-size: 14px;
  }
}

h1 {
  color: #1d2d44;
  font-weight: 600;
  font-size: 48px;
  margin: 0px;
  @media only screen and (max-width: 760px) {
    font-size: 36px;
  }
}

h3 {
  color: #1d2d44;
}

h4 {
  color: #1d2d44;
  font-size: 26px;
  font-weight: 600;
  margin: 0px;
  @media only screen and (max-width: 640px) {
    font-size: 18px;
  }
}

h6 {
  color: #1d2d44;
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
  @media only screen and (max-width: 640px) {
    font-size: 14px;
  }
}

h5 {
  color: #3e5c76;
  font-size: 16px;
  font-weight: 600;
  font-style: italic;
  margin: 0px;
  @media only screen and (max-width: 640px) {
    font-size: 14px;
  }
}

a {
  font-size: 20px;
}

.home-container {
  display: flex;
  flex-direction: column;
  gap: 44px;
  @media only screen and (max-width: 760px) {
    gap: 22px;
  }
}
.parents-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .parents-1 {
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: left;
  }
  .parents-2 {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: right;
  }
}

.brides-groom-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {
    padding: 0px 20px;
  }
}

.save-the-date {
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
