.go-waze {
  padding: 0px;
  align-self: center;
  .go-waze-container {
    align-items: center;
    background-color: white;
    border-radius: 28px;
    display: inline-flex;
    font-weight: 700;
    margin: 9px;
    padding: 0 20px 0 0;
    cursor: pointer;
    transition: 0.25s;
    &:hover {
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }

    .go-waze-icon {
      align-items: center;
      background-color: #3cf;
      border-radius: 24px;
      display: inline-flex;
      justify-content: center;
      margin: 6px;
      height: 44px;
      width: 44px;
      margin-right: 10px;
    }
    .go-waze-title {
      margin: 0px;
      font-size: 16px;
      padding-right: 20px;
    }
  }
}
