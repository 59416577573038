.language-toggle-switch {
  display: flex;
  flex-direction: row;
  justify-content: right;
  gap: 8px;
  .language-item {
    padding: 10px 8px;
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid #0d1321;
    &:active {
      background-color: #3e5c76;
    }
  }
  .language-item-active {
    background-color: #3e5c76;
  }
}
